
import { Options, Vue, Watch } from "vue-property-decorator";
import * as api from "@/api/anquanxunjian.ts";
import fileApi from "@/api/file/index.ts";
@Options({})
export default class extends Vue {
  private show = false;
  infodata: any = {
    flag: 0,
  };
  active = -1;
  history = [];
  remark = "";
  uploader = [];
  checkUploader = [];
  queryPloyLoad: any = {};
  previewimg = "";
  mounted() {
    this.queryPloyLoad = this.$route.params;
    console.log("this.queryPloyLoad", this.queryPloyLoad);
    this.fGetDetailData();
  }
  fGetDetailData() {
    let reqFun = null;
    if (this.queryPloyLoad.status != 4) {
      reqFun = api.mobilesourceCheckgetById(this.queryPloyLoad.id);
    } else {
      reqFun = api.projectDangerCheckgetById(this.queryPloyLoad.id);
    }
    reqFun.then((res: any) => {
      this.infodata = res.data;
      if (this.infodata.dealList.length > 0) {
        if (this.infodata.dealList[0].status === 3) {
          this.active = 0;
        }
      }
    });
  }
  afterRead(files: any) {
    let filearr = null;
    if (!Array.isArray(files)) {
      filearr = [files];
    } else {
      filearr = [...files];
    }
    filearr.map((file) => {
      file.status = "uploading";
      file.message = "上传中...";
      fileApi
        .uploadFile("inspectionmobile/", file.file.name, file.file, true)
        .then((res: any) => {
          file.status = "done";
          file.id = res.data.id;
        });
    });
  }
  fExamine(type: number) {
    let data = {};
    let arr = "";
    this.uploader.map((item: any) => {
      arr += "," + item.id;
    });
    let checkArr = "";
    this.checkUploader.map((item: any) => {
      checkArr += "," + item.id;
    });
    if (type === 1) {
      data = {
        checkId: this.infodata.id,
        remark: this.remark,
        fileIds: arr.substr(1, arr.length - 1),
        checkFileIds: checkArr.substr(1, checkArr.length - 1),
        status: 3,
      };
    } else {
      data = {
        checkId: this.infodata.id,
        remark: this.remark,
        fileIds: arr.substr(1, arr.length - 1),
        checkFileIds: checkArr.substr(1, checkArr.length - 1),
        status: 1,
      };
    }
    this.$dialog.confirm({
      title: "是否确认" + (type === 1 ? "通过" : "不通过") + "？",
      message: "",
      beforeClose: (action: string) => {
        if (action === "confirm") {
          api.mobilesourceDealsaveCheck(data).then((res: any) => {
            if (res.code === 0) {
              this.$toast.success({
                message: '处理成功',
                forbidClick: true,
                onClose: () => {
                  (this as any).$mitter.emit("refresh");
                  this.$router.go(-1);
                  return true;
                },
              });
            }
          });
        } else {
          return true;
        }
      },
    });
  }
  handle() {
    this.$dialog.confirm({
      title: "是否确认提交？",
      message: "",
      beforeClose: (action: string) => {
        if (action === "confirm") {
          let arr = "";
          this.uploader.map((item: any) => {
            arr += "," + item.id;
          });
          let checkArr = "";
          this.checkUploader.map((item: any) => {
            checkArr += "," + item.id;
          });
          const data = {
            checkId: this.infodata.id,
            remark: this.remark,
            fileIds: arr.substr(1, arr.length - 1),
            checkFileIds: checkArr.substr(1, checkArr.length - 1),
            status: 2,
          };
          api.mobilesourceDealsaveDeal(data).then((res: any) => {
            if (res.code === 0) {
              this.$toast.success({
                message: "提交成功",
                forbidClick: true,
                onClose: () => {
                  (this as any).$mitter.emit("refresh");
                  this.$router.go(-1);
                  return true;
                },
              });
            }
          });
        } else {
          return true;
        }
      },
    });
  }
  previewimgfun(arg: string) {
    this.previewimg = arg;
    this.show = true;
  }
}
